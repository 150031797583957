<template>
  <div>
    <b-modal v-model="modalImprovements" :title=" $t('components.product.improvements.title') " content-class="card "
      centered header-class="card-header" @hidden="fnResetModalForms()" cancel-variant="danger" size="md"
      hide-header-close>
      <div>
        <product-card :product="productInfo"></product-card>
        <hr>
        <div class=" mb-0">
          <div class=" ">
            <v-observer tag="form" ref="generalInformationForm" @submit.prevent="fnApiUpdateGeneralInformation()">
              <div class="row ">
                <div class="col-12 " v-if=" tempProductInfo.price == null || tempProductInfo.price == 0  ">
                  <div class="row">
                    <div class="col-8">
                      <strong v-text="$t('general.form.price')"></strong>
                    </div>
                    <div class="col-4 text-right">
                      <strong class="text-success">
                        15%
                      </strong>
                    </div>
                    <div class="col-12">
                      <p> <span v-text=" $t('components.product.improvements.priceMessage') "></span> </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 " v-if=" tempProductInfo.imageUrl == null ">
                  <div class="row">
                    <div class="col-8">
                      <strong v-text="$t('general.form.image')"></strong>
                    </div>
                    <div class="col-4 text-right">
                      <strong class="text-success">
                        15%
                      </strong>
                    </div>
                    <div class="col-12">
                      <p> <span v-text=" $t('components.product.improvements.imageMessage') "></span> </p>
                    </div>
                  </div>
                </div>

                <div class="col-12 " v-if=" tempProductInfo.name == null ">
                  <v-validation rules="required" v-slot="{ errors }" :name=" 'Name' ">
                    <div class="form-group">
                      <label for="" class="w-100">
                        <div class="row">
                          <div class="col-8">
                            <strong>Name</strong>
                          </div>
                          <div class="col-4 text-right">
                            <strong class="text-success">
                              15%
                            </strong>
                          </div>
                        </div>
                      </label>

                      <input type="text" class="form-control" v-model=" productInfo.name "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="invalid-feedback " v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                </div>

                <div class="col-12" v-if=" tempProductInfo.description == null ">
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.description') ">
                    <div class="form-group">
                      <label for="" class="w-100">
                        <div class="row">
                          <div class="col-8">
                            <strong v-text="$t('general.form.description')"></strong>
                          </div>
                          <div class="col-4 text-right">
                            <strong class="text-success">
                              15%
                            </strong>
                          </div>
                        </div>
                      </label>
                      <vue-editor class=" " v-model="productInfo.description" :editor-toolbar="CONST.CUSTOMTOOLBAR"
                        :class=" { 'is-invalid ':errors.length > 0 } "></vue-editor>
                      <div class="invalid-feedback " v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                </div>

                <div class="col-12" v-if=" tempProductInfo.tags == null ||  tempProductInfo.tags.length < 5  ">
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.tags') ">
                    <div class="form-group">
                      <label for="" class="w-100">
                        <div class="row">
                          <div class="col-8">
                            <strong v-text="$t('general.form.tags')"></strong> <span
                              v-text=" ' ( ' + $t('general.form.minimum') + ' 5 )' "> </span>
                          </div>
                          <div class="col-4 text-right ">
                            <strong class="text-success">
                              10%
                            </strong>
                          </div>
                        </div>
                      </label>
                      <div class="input-group ">
                        <b-form-tags v-model="productInfo.tags" class=" " :placeholder="$t('general.form.addTags')"
                          tag-variant="primary" separator="," :class=" { 'is-invalid':errors.length > 0 } ">
                        </b-form-tags>
                        <div class="input-group-append">
                          <span class="input-group-text"> <i class="fa fa-tags"></i> </span>
                        </div>
                        <div class="invalid-feedback " v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                  </v-validation>
                </div>

                <!-- CATEGORIES -->
                <div class="col-12" v-if=" categoryName == null ">
                  <div class="row mb-2">
                    <div class="col-8">
                      <strong v-text="$t('general.form.category')"> </strong>
                    </div>
                    <div class="col-4 text-right ">
                      <strong class="text-success">
                        10%
                      </strong>
                    </div>
                  </div>
                  <div class="row justify-items-center">
                    <div class="col">
                      <p> <span v-text=" $t('general.form.current') + ': ' "></span> <strong
                          v-text="  categoryName ? categoryName : $t('general.form.none')  "></strong> </p>
                    </div>
                    <div class="col-auto">
                      <button type="button" class="btn p-0 border border-dark-gray  px-1"
                        @click=" showCategory = !showCategory ">
                        <i class=" fa " :class=" !showCategory ? 'fa-pencil-alt' : 'fa-chevron-up' "></i>
                      </button>
                    </div>
                  </div>
                  <div v-if=" showCategory ">
                    <v-validation :rules=" showCategory ? 'required' : '' " v-slot="{ errors }" :name=" 'Category' ">
                      <div class="form-group">
                        <div class=" form-control p-0"
                          :class=" errors.length > 0 ? 'is-invalid border-danger ': 'border-dark-gray' ">
                          <v-tree class=" " v-model="productInfo.category.id" :load-options="fnApiGetCategories"
                          :loadingText="$t('tables.loading') + '...' " :noResultsText="$t('messages.noResults') + '...' "
                            :async="true" :placeholder="$t('general.form.placeholderCategory')"
                            :defaultOptions="categories" :normalizer="fnNormalizer"> </v-tree>
                        </div>
                        <div class="invalid-feedback " v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </v-validation>
                  </div>
                </div>

                <div class="col-12"
                  v-if=" tempProductInfo.dimensions == null || tempProductInfo.dimensions.width == null || tempProductInfo.dimensions.width == 0 ">
                  <div class="row">
                    <div class="col-8">
                      <strong v-text="$t('general.form.dimensions')"></strong>
                    </div>
                    <div class="col-4 text-right ">
                      <strong class="text-success">
                        20%
                      </strong>
                    </div>

                    <div class="col-6 ">
                      <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.width') ">
                        <div class="form-group">
                          <label for="" v-text=" $t('general.form.width') "></label>
                          <div class="input-group mb-3">
                            <input type="number" min="1" step="1" class="form-control"
                              v-model=" productInfo.dimensions.width " :class=" { 'is-invalid':errors.length > 0 } ">
                            <div class="input-group-append">
                              <span class="input-group-text rounded-right " id="unitsWidth"> <strong
                                  v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </v-validation>
                    </div>

                    <div class="col-6 ">
                      <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.height') ">
                        <div class="form-group">
                          <label for="" v-text=" $t('general.form.height') "></label>
                          <div class="input-group">
                            <input type="number" min="1" step="1" class="form-control"
                              v-model=" productInfo.dimensions.height  " :class=" { 'is-invalid':errors.length > 0 } ">
                            <div class="input-group-append">
                              <span class="input-group-text rounded-right" id="unitsHeight"> <strong
                                  v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </v-validation>
                    </div>

                    <div class="col-6 ">
                      <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.length')  ">
                        <div class="form-group">
                          <label for="" v-text=" $t('general.form.length')  "></label>
                          <div class="input-group">
                            <input type="number" min="1" step="1" class="form-control"
                              v-model=" productInfo.dimensions.length  " :class=" { 'is-invalid':errors.length > 0 } ">
                            <div class="input-group-append">
                              <span class="input-group-text rounded-right" id="unitsDepth"> <strong
                                  v-text=" CONST.UNITS.LENGTH "> </strong> </span>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </v-validation>
                    </div>

                    <div class="col-6 ">
                      <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.weight') ">
                        <div class="form-group">
                          <label for="" v-text=" $t('general.form.weight') "></label>
                          <div class="input-group">
                            <input type="number" min="0.1" step="0.1" class="form-control"
                              v-model=" productInfo.dimensions.weight  " :class=" { 'is-invalid':errors.length > 0 } ">
                            <div class="input-group-append">
                              <span class="input-group-text rounded-right" id="unitsWeight"> <strong
                                  v-text=" CONST.UNITS.WEIGHT "> </strong> </span>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                              {{ errors[0] }}
                            </div>
                          </div>
                        </div>
                      </v-validation>
                    </div>

                  </div>
                </div>
              </div>
            </v-observer>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <p>
          <button @click=" modalImprovements != modalImprovements ;  fnResetModalForms() " class="btn btn-sm"
            v-text=" $t('general.button.close') "></button>
          <button class=" btn btn-sm btn-success" :disabled="sendingData" v-show="showBtnUpdate"
            @click="fnApiUpdateGeneralInformation()">
            <span v-text=" $t('general.button.update') " v-if=" !sendingData "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </p>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import {
    VueEditor
  } from "vue2-editor";
  export default {
    name: "ProductImprovementsModal",
    props: {
      showImprovementsModal: {
        type: Boolean,
        default: false,
      },
      typeQuick: {
        type: String,
        required: false,
      },
      productInfo: {
        type: Object,
        required: false,
      },
      categories: {
        type: Array,
        required: true,
      },
    },
    watch: {
      showImprovementsModal() {
        this.modalImprovements = true;
      },
      productInfo() {

        if (this.productInfo.category && this.productInfo.category.name) {
          this.categoryName = this.productInfo.category.name;
        } else {
          this.showCategory = true;
        }

        if (this.productInfo != null && this.productInfo.category) {
          this.category.id = this.productInfo.category.id || null;
        }
        if (this.productInfo.dimensions == null) {
          this.productInfo.dimensions = {
            width: null,
            length: null,
            weight: null,
            height: null,
          }
        }
        this.tempProductInfo = this.$lodash.cloneDeep(this.productInfo);

        // BTNUPDATE
        if (this.tempProductInfo.name == null) {
          this.showBtnUpdate = true;
        }
        if (this.tempProductInfo.description == null) {
          this.showBtnUpdate = true;
        }
        if (this.tempProductInfo.tags == null || this.tempProductInfo.tags.length < 5) {
          this.showBtnUpdate = true;
        }
        if (this.tempProductInfo.category.name == null) {
          this.showBtnUpdate = true;
        }
        if (this.tempProductInfo.dimensions == null || this.tempProductInfo.dimensions.width == null || this
          .tempProductInfo.dimensions.width == 0) {
          this.showBtnUpdate = true;
        }

      }
    },
    components: {
      VueEditor
    },
    data() {
      return ({
        sendingData: false,
        modalImprovements: false,
        showBtnUpdate: false,
        category: {
          id: null,
        },
        // categories: [],
        showCategory: false,
        categoryName: null,
        tempProductInfo: {
          dimensions: {
            width: null,
            length: null,
            weight: null,
            height: null,
          }
        },
      })
    },
    methods: {

      async fnApiUpdateGeneralInformation() {
        if (await this.$refs['generalInformationForm'].validate()) {
          this.sendingData = true;

          let {
            name,
            description,
            tags,
            category,
            dimensions
          } = this.productInfo;
          let productData = {
            name,
            description,
            tags
          };

          if (this.tempProductInfo.name == null) {
            productData.name = name;
          }

          if (this.tempProductInfo.description == null) {
            productData.description = description;
          }

          if (this.tempProductInfo.tags.length == 0) {
            productData.tags = tags;
          }

          productData.dimensions = dimensions;

          if (category.id) {
            productData.category = {
              id: category.id,
            }
          }

          await axios.put('products/' + this.productInfo.id, productData).then((response) => {
            this.$emit('update-product', response.data);
            this.$toasted.global.infoMessage(this.$t('toasted.info.productUpdated'));
            this.fnResetModalForms()
          }).catch(error => {});
          this.sendingData = false;
        }
      },

      // Sell out of stock
      async fnApiSellOutOfStock() {
        this.sendingData = true;
        await axios.put('../products', {
          sell_out_stock: true,
          ids: this.productArray
        }).then((response) => {
          this.$emit('update-products', response.data);
          this.fnResetModalForms();
          this.$toasted.global.infoMessage(this.$t('toasted.info.productSellOuOfStockUpdate'));
        }).catch(error => {});
        this.sendingData = false;
      },

      fnResetModalForms() {
        this.showCategory = false;
        this.modalImprovements = false;
        this.category = {
            id: null,
          },
          this.showBtnUpdate = false;
        this.categoryName = null;
        this.$emit('close-modal');
      },

      // fnGetCatrgories() {
      //   axios.get('categories/root').then((response) => {
      //     this.categories = response.data;
      //   }).catch(error => {});
      // },

    },
    mounted() {
      // this.fnGetCatrgories();
    }
  }
</script>

<style>

</style>